const CAMPAIGN_PARAM_NAME = 'utm_campaign';
const CAMPAIGN_STORE_NAME = 'jgd_campaign';
// Expire in 30 days
const CAMPAIGN_EXPIRATION = 30 * 24 * 60 * 60 * 1000;

let channelsCache = [];

const getMarketingSourceFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const urlParam = urlParams.get(CAMPAIGN_PARAM_NAME);
  if (urlParam) {
    return urlParam.toLowerCase();
  }
  return null;
};

const saveMarketingSourceToLocalStorage = (marketingSource) => {
  const expiration = new Date().getTime() + CAMPAIGN_EXPIRATION;
  localStorage.setItem(CAMPAIGN_STORE_NAME, JSON.stringify({ value: marketingSource, expiration }));
};

const getMarketingSourceFromLocalStorage = () => {
  const marketingSource = JSON.parse(localStorage.getItem(CAMPAIGN_STORE_NAME));
  if (!marketingSource) return '';
  if (marketingSource.expiration < new Date().getTime()) {
    localStorage.removeItem(CAMPAIGN_STORE_NAME);
    return '';
  }
  return marketingSource.value;
};

const fetchChannels = async () => {
  if (channelsCache.length) return channelsCache;
  const cacheBuster = new Date().getTime();
  const response = await fetch(`${import.meta.env.VITE_BASE_SEGMENT}/campaigns.json?${cacheBuster}`);
  channelsCache = response.json();
  return channelsCache;
};

const getChannelFromKey = (key, channels) => {
  const channel = channels.find((channel) => channel.key === key);
  if (channel && channel.phone) {
    // Remove dashes, spaces and parentheses from phone number
    channel.hrefPhone = 'tel:' + channel.phone.replace(/[-\s()]/g, '');
  }
  return channel;
};

const getChannel = async () => {
  const sourceParam = getMarketingSourceFromUrl();
  const channels = await fetchChannels();
  let channel;

  if (sourceParam) {
    channel = getChannelFromKey(sourceParam, channels);
    if (channel) {
      saveMarketingSourceToLocalStorage(sourceParam);
      return channel;
    }
  }
  const sourceKey = getMarketingSourceFromLocalStorage();
  if (sourceKey) {
    channel = getChannelFromKey(sourceKey, channels);
    if (channel) {
      saveMarketingSourceToLocalStorage(sourceKey);
      return channel;
    }
  }
  return null;
};

export { getChannel };
